<!-- @format -->
<template>
  <div class="px-6">
    <div
      class="w-full flex flex-col gap-2 pt-2"
      v-if="!outcome || parseInt(isManager) || outcome.length == 0"
    >
      <label class="text-sm"> Select Status </label>
      <v-select
        v-model="selectedStatus"
        label="key"
        placeholder="Select Status"
        :options="status"
        @update:modelValue="errors.status = null"
      ></v-select>
      <span v-if="errors.status" class="text-red-500 text-xs">{{
        errors.status
      }}</span>
    </div>

    <div class="flex flex-col mt-5 gap-2">
      <label class="text-sm">{{
        isEnabled === 1 ? "Enter Conclusion" : "Conclusion"
      }}</label>
      <textarea
        v-model="conclusion"
        @change="errors.conclusion = null"
        type="text"
        :maxlength="1000"
        :class="outcome.length != 0 ? 'isEdit' : ''"
        class="border border-gray-200 p-2 rounded h-44"
        placeholder="Write Details Here"
        :disabled="complaintStatus > 1 ? true : false"
      ></textarea>
      <span v-if="errors.conclusion" class="text-red-500 text-xs">{{
        errors.conclusion
      }}</span>
    </div>

    <div
      class="flex items-center justify-center border-teal border-dashed border-2 h-32 rounded-md mt-8 cursor-pointer"
      v-bind="getRootProps()"
      v-if="!acceptedFiles"
    >
      <input v-bind="getInputProps()" />
      <div class="flex items-center">
        Drag or upload files here or
        <div class="cursor-pointer text-teal">&nbsp;Browse here</div>
        <div class="material-icons text-teal pl-1">attachment_outlined</div>
      </div>
    </div>
    <div v-if="acceptedFiles">
      <div
        class="p-4 flex w-full gap-4 rounded-md bg-teal bg-opacity-10 mt-4 justify-between"
      >
        <div class="flex gap-4">
          <div
            class="flex items-center justify-start"
            v-if="!outcome || parseInt(isManager) || outcome.length == 0"
          >
            <span
              v-if="loader"
              class="border-2 border-black rounded-full w-4 h-4 animate-spin"
            ></span>
          </div>
          <div v-for="(item, key) in acceptedFiles" :key="key">
            <div class="w-full bg-teal bg-opacity-25 px-2 rounded-md">
              <div
                class="text-md line-clamp-2 capitalize flex items-center"
                :class="outcome.length !== 0 ? ' cursor-pointer' : ''"
                @click="
                  outcome.length !== 0
                    ? downloadFile(item?.file, item?.name)
                    : ''
                "
              >
                {{
                  item
                    ? item.name?.length > 35
                      ? item.name?.slice(0, 25) + "..." + item.name?.slice(-8)
                      : item.name
                    : ""
                }}
                <span
                  class="material-icons w-5 text-red px-1 cursor-pointer text-lg"
                  v-if="complaintStatus == 1"
                  @click="removeSingleFile(key)"
                  >close
                </span>
                <span
                  class="material-icons w-5 text-teal px-1 text-lg cursor-pointer"
                  v-if="complaintStatus > 1"
                  >save_alt_icon</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex mt-2"
        :class="
          errors.acceptedFiles || !isFileUploaded.flag
            ? 'justify-between'
            : 'justify-end'
        "
      >
        <small v-if="errors.acceptedFiles" class="text-red">{{
          errors.acceptedFiles
        }}</small>
        <small v-if="!isFileUploaded.flag" class="text-red">{{
          isFileUploaded.msg
        }}</small>
        <div
          v-if="
            loader || parseInt(!isManager) || outcome.length == 0 || !outcome
          "
        >
          <div v-if="acceptedFiles?.length < 4">
            <div
              v-bind="getRootProps()"
              class="flex items-center justify-end cursor-pointer"
            >
              <input v-bind="getInputProps()" />
              <div
                class="material-icons text-teal w-5 transform -rotate-90 text-2xl mr-1"
              >
                attachment_outlined
              </div>
              <button
                class="cursor-pointer text-sm text-gray-600"
                :disabled="loader"
              >
                Add more Files
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end pt-4" v-if="!outcome || outcome?.length == 0">
      <button
        class="py-3 px-10 text-white rounded-md font-light"
        @click="saveForm"
        :class="loader ? 'bg-teal bg-opacity-10 cursor-wait' : 'bg-teal'"
        :disabled="loader"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, toRefs, watch } from "vue";
import { useDropzone } from "vue3-dropzone";
import { useStore } from "vuex";
import SuccessIcon from "@/assets/icons/success.svg";
import Swal from "sweetalert2";
import { useRoute, useRouter } from "vue-router";
import useDownloadFile from "@/composables/useDownloadFile";
const props = defineProps({
  isEnabled: {
    type: Number,
  },
  outcome: {
    type: Object,
    default: () => {},
  },
  isManager: {
    type: Boolean,
    default: false,
  },
  complaintStatus: {
    type: Number,
  },
});
const { isEnabled, outcome, isManager, complaintStatus } = toRefs(props);

const { getRootProps, getInputProps, ...rest } = useDropzone({
  onDrop,
  maxSize: 2000000,
  maxFiles: 4,
  accept: [".pdf", ".docx"],
});

const { downloadFile } = useDownloadFile();

const route = useRoute();
const store = useStore();
const complaintId = ref(route.params.complaintId);
const router = useRouter();
const loader = ref(false);

const status = ref([
  { id: 2, key: "Upheld" },
  { id: 3, key: "Partially Upheld" },
  { id: 4, key: "Not Upheld" },
]);

const errors = ref({
  conclusion: null,
  status: null,
  acceptedFiles: null,
});

const conclusion = ref();
const isFileUploaded = ref({ flag: true, msg: "" });
const selectedStatus = ref();
const restVlaue = ref(null);
const acceptedFiles = ref(null);

function onDrop(acceptedFilesNew, rejectReasons) {
  errors.value.acceptedFiles = "";
  if (acceptedFiles.value) {
    if (acceptedFiles.value?.length + acceptedFilesNew.length > 4) {
      isFileUploaded.value.flag = false;
      isFileUploaded.value.msg = "You can upload 4 files at max!";
    } else {
      acceptedFiles.value = [...acceptedFiles.value, ...acceptedFilesNew];
      isFileUploaded.value.flag = true;
      isFileUploaded.value.msg = "";
      if (rejectReasons) {
        rejectReasons.map((item) => {
          if (item.errors[0].message.includes("Too many files")) {
            isFileUploaded.value.flag = false;
            isFileUploaded.value.msg = "You can upload 4 files at max!";
          }
          if (
            item.errors[0].message.includes("File is larger than 2000000 bytes")
          ) {
            isFileUploaded.value.flag = false;
            isFileUploaded.value.msg = "File Size Must Be Smaller Than 2MB";
          }
        });
      }
      restVlaue.value = rest;
    }
  } else {
    acceptedFiles.value = acceptedFilesNew;
    isFileUploaded.value.flag = true;
    isFileUploaded.value.msg = "";
    if (rejectReasons) {
      rejectReasons.map((item) => {
        if (item.errors[0].message.includes("Too many files")) {
          isFileUploaded.value.flag = false;
          isFileUploaded.value.msg = "You can upload 4 files at max!";
          acceptedFiles.value = null;
        }
        if (
          item.errors[0].message.includes("File is larger than 2000000 bytes")
        ) {
          isFileUploaded.value.flag = false;
          isFileUploaded.value.msg = "File Size Must Be Smaller Than 2MB";
          acceptedFiles.value = null;
        }
      });
    }
    restVlaue.value = rest;
  }
}

const removeSingleFile = (id) => {
  if (acceptedFiles.value.length === 1) {
    acceptedFiles.value = null;
  } else {
    acceptedFiles.value.splice(id, 1);
  }
};

const validateForm = () => {
  let valid = true;
  if (!conclusion.value || conclusion.value.trim() === "") {
    errors.value.conclusion = "Conclusion is required.";
    valid = false;
  } else {
    errors.value.conclusion = "";
  }
  if (!selectedStatus.value) {
    errors.value.status = "Status is required.";
    valid = false;
  } else {
    errors.value.status = "";
  }
  if (!acceptedFiles.value) {
    errors.value.acceptedFiles = "Files are required";
    valid = false;
  } else {
    errors.value.acceptedFiles = "";
  }
  return valid;
};

const saveForm = () => {
  if (validateForm()) {
    const formData = new FormData();
    formData.append("conclusion", conclusion.value);
    formData.append("status", selectedStatus.value.id);
    acceptedFiles.value.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
    loader.value = true;
    formData.append("complaint", complaintId.value);
    store
      .dispatch("hqComplaints/submitOutcome", formData)
      .then(() => {
        loader.value = false;
        Swal.fire({
          toast: true,
          position: "top-end",
          title: "Complaint Outcome is Submitted Successfully!",
          iconHtml: `<img src="${SuccessIcon}" />`,
          width: "32em",
          customClass: {
            title: "popup-title",
            popup: "popup-border",
            content: "popup-text",
            icon: "popup-icon-border",
          },
          didOpen: () => {
            router.push({
              name: "hq-monitoringOfPracticeFunctionComplaintsDashboard",
            });
          },
          timer: 2000,
          showConfirmButton: false,
          background: `rgba(225, 249, 240, 1)`,
        });
      })
      .catch(() => {
        loader.value = false;
      });
  } else {
    return;
  }
};

watch(
  () => outcome.value,
  (newValue) => {
    conclusion.value = newValue?.conclusion;
    acceptedFiles.value = newValue?.complaint_outcome_files?.map((file) => ({
      id: file?.id,
      complaint: file?.complaint,
      file: file?.file,
      name: file?.file_name,
    }));
  },
  { immediate: true }
);
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 7px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
::v-deep .vs__search {
  --vs-search-input-placeholder-color: rgb(148, 148, 148);
}
.swal2-cancel.swal2-styled {
  border-color: red !important;
}

input[type="radio"]#is-visible {
  accent-color: teal;
}

.isEdit {
  background: rgba(231, 243, 242, 0.65);
}
</style>
