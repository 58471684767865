<!-- @format -->

<template>
  <div>
    <div
      v-if="loader"
      class="animate-pulse pl-2 relative mx-5 my-4 cursor-wait"
    >
      <div class="border-l-2 border-gray-300 h-full top-1" style="left: 7px">
        <ul class="list-none m-0 p-0">
          <li class="mb-2">
            <div class="flex items-center">
              <div
                class="bg-gray-300 rounded-full h-4 w-4 absolute -ml-2"
              ></div>
              <div class="flex-1 ml-4 font-normal">
                <div
                  class="h-2.5 mb-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mt-2.5"
                ></div>
              </div>
            </div>
            <div
              class="h-10 mb-1 ml-4 bg-gray-100 rounded dark:bg-gray-200 mt-2.5"
            ></div>
            <div
              class="h-8 w-44 mb-1 ml-4 bg-gray-100 rounded dark:bg-gray-200 mt-2.5"
            ></div>
          </li>
        </ul>
      </div>
    </div>
    <div class="relative mx-5 my-4">
      <div v-for="data in updatesTimeline" :key="data" class="pb-4">
        <div
          class="border-r-2 border-teal absolute h-full top-1"
          style="left: 7px"
        ></div>
        <ul class="list-none m-0 p-0">
          <li class="mb-2">
            <div class="flex items-center mb-1">
              <div class="bg-teal rounded-full h-4 w-4"></div>
              <div class="flex-1 ml-4 font-normal">
                {{ moment(data?.created_at).format("MMM DD, YYYY") }}
              </div>
            </div>
            <div
              class="ml-8 border border-teal rounded-md p-3 bg-lightTeal font-normal text-xs overflow-hidden break-words"
              v-html="data?.update"
            ></div>
            <div
              class="ml-8 border border-teal rounded-md p-2 bg-lightTeal font-normal text-xs my-2 inline-flex items-center gap-1"
              v-for="file in data?.complaint_update_files"
              :key="file.id"
            >
              <img
                class="w-6"
                src="../../../assets/icons/compliance_File.svg"
              />
              <div class="w-full">
                {{
                  file
                    ? file?.file_name?.length > 15
                      ? file?.file_name.slice(0, 8) +
                        "..." +
                        file?.file_name.slice(-4)
                      : file?.file_name
                    : ""
                }}
              </div>
              <div
                class="flex items-center justify-end cursor-pointer"
                @click="downloadFile(file?.file, file?.file_name)"
              >
                <span class="material-icons w-5 text-teal px-1 text-lg"
                  >save_alt_icon</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from "vue";
import useDownloadFile from "@/composables/useDownloadFile";
import moment from "moment";
const props = defineProps({
  updatesTimeline: {
    type: Array,
    reqired: true,
    default: () => [],
  },
  loader: {
    type: Boolean,
  },
});

const { updatesTimeline, loader } = toRefs(props);
const { downloadFile } = useDownloadFile();
</script>
