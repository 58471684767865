export default function useDownloadFile() {
  function downloadFile(fileUrl) {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    const fileExtension = fileUrl.split('.').pop();
    const fileName = `downloaded-file.${fileExtension}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return {
    downloadFile,
  };
}
